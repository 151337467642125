import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'

import { Filter, Props as FilterProps } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  offers?: OfferProps[]
}

export const OffersList = memo(function OffersList({
  filters,
  languageCode,
  offers,
}: Props) {
  if (!offers) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {filters ? (
        <FadeInOutAnimation>
          <Filters dial={2} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              label={useVocabularyData('all', languageCode)}
              onClick={() => setFilteredCategory('all')}
            />
            {filters.map((item, index) => {
              const category = item.category
                ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
                : undefined

              return (
                <Filter
                  active={filteredCategory === category}
                  key={index}
                  {...item}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                />
              )
            })}
          </Filters>
        </FadeInOutAnimation>
      ) : null}
      <List row wrap space="between">
        {offers.map((item, index) => {
          const category = item.category
            ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
            : undefined
          return (
            <FadeInOutAnimation
              key={index}
              className={
                filteredCategory === category || filteredCategory === 'all'
                  ? 'visible'
                  : undefined
              }
            >
              <Offer {...item} />
            </FadeInOutAnimation>
          )
        })}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem 0 13.4375rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 5.25rem 0 9.75rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 5.25rem;
  }
`

const Filters = styled(FlexBox)`
  border-top: 2px solid ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 0 1.5rem;
`

const List = styled(FlexBox)`
  max-width: 73.5rem;
  margin: auto;
  padding: 0 1.5rem;
  > div {
    display: none;
    width: calc(50% - 3rem);
    margin-top: 4.5rem;
    &.visible {
      display: block;
    }
  }

  @media (max-width: 767px) {
    > div {
      width: 100%;
      margin-top: 3rem;
    }
  }
`
