import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import LazyLoad from 'react-lazyload'

export interface Props {
  category?: string
  image?: string
  languageCode: string
  priceFrom?: string
  title: string
  URL?: string
  validity?: string
}

export const Offer = memo(function Offer({
  category,
  image,
  languageCode,
  priceFrom,
  title,
  URL,
  validity,
}: Props) {
  return (
    <Container data-category={category} to={URL ? URL : ''}>
      <Item>
        {image ? (
          <LazyLoad>
            <BackgroundImage
              className="offer-bg-image"
              style={{ backgroundImage: `url(${image})` }}
            />
            <ImageWrap className="offer-image">
              <Image style={{ backgroundImage: `url(${image})` }} />
            </ImageWrap>
          </LazyLoad>
        ) : null}
        <TextWrap className="offer-text-wrap" dial={5} tag="span">
          {category ? <Category>{category}</Category> : null}
          <Title className="offer-title">{title}</Title>
          {URL ? (
            <CTA
              className="offer-cta"
              label={useVocabularyData('details', languageCode)}
              noActions
            />
          ) : null}
        </TextWrap>
      </Item>
      <Info className="offer-info" dial={8} row space="between" tag="span">
        {validity ? (
          <Validity dial={5} row tag="span">
            <Icon.Tag />
            {validity}
          </Validity>
        ) : null}
        {priceFrom ? (
          <PriceFrom dial={8} row>
            <PriceLabel>
              {useVocabularyData('starting-from', languageCode)}
            </PriceLabel>
            <PriceValue>{priceFrom}</PriceValue>
          </PriceFrom>
        ) : null}
      </Info>
    </Container>
  )
})

const Container = styled(Link)`
  &:hover {
    .offer-bg-image {
      opacity: 0.7;
    }
    .offer-image {
      &:before {
        opacity: 0.7;
      }
      > span {
        transform: scale(1);
      }
    }
    .offer-cta {
      bottom: 0;
      opacity: 1;
    }
    .offer-info {
      opacity: 0.6;
    }
  }
`

const ImageWrap = styled.span`
  width: calc(100% - 2.5rem);
  height: calc(100% - 5rem);
  overflow: hidden;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-out;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const BackgroundImage = styled.span`
  width: 56%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-out;

  @media (max-width: 767px) {
    display: none;
  }
`

const Item = styled.span`
  display: block;
  height: 100%;
  padding-bottom: 82%;
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 0;
  }
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 2.5rem);
  height: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 6rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 1023px) {
    padding-bottom: 9rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem 0;
    position: relative;
    top: 0;
    left: 0;
  }
`

const Category = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  opacity: 0.4;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: block;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`

const CTA = styled(Button)`
  position: absolute;
  bottom: -1rem;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.3s ease-out;

  @media (max-width: 1023px) {
    opacity: 1;
    bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 3rem;
    position: relative;
    left: 0;
    transform: none;
  }
`

const Info = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-top: 1rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
`

const Validity = styled(FlexBox)`
  padding-right: 1.5rem;
  text-align: left;
  transform: translateY(-0.3125rem);
  svg {
    height: 0.75rem;
    margin-right: 0.375rem;
  }
`

const PriceFrom = styled(FlexBox)`
  text-align: right;
`

const PriceLabel = styled.span`
  transform: translateY(-0.3125rem);
  white-space: nowrap;
`

const PriceValue = styled.span`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-left: 0.3125rem;
  white-space: nowrap;
  &:after {
    content: '';
    width: 2.25rem;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    margin-left: 0.5625rem;
  }
`
